.shop-modal-wrapper {
  padding: 0 10px;
}
.shop-modal-info {
  background: #fff;
  border-radius: 7px;
  overflow: hidden;

  &-padded {
    padding: 20px;
  }

  &-header {
    padding: 20px 0;
    border-bottom: 1px solid rgba($color: #ccc, $alpha: .5);
    h3, h4 {
      text-align: center;
      margin: 0 0 5px;
    }
    h4 {
      font-weight: 200;
    }
  }

  &-body {
    &-main {
      display: flex;
      &-left {
        flex-basis: 75%;
        p {
          margin: 0;
        }
        .main-info-name {
          font-weight: bold;
          margin-bottom: 5px;
        }
        .main-info-hours-before-closing {
          font-style: italic;
          color: var(--greenish-teal)
        }
      }
      &-right {
        margin-top: auto;
        margin-left: auto;
        color: rgb(80, 80, 80);
        opacity: .7
      }
    }
    &-secondary {
      p {
        color: #505050;
        line-height: 1.3em;
      }
    }
  }
  &-actions {
    width: 100%;
    button {
      width: 50%;
      box-sizing: border-box;
      border: none;
      color: #fff;
      padding: 10px 0;
      font-size: 18px;
      margin: 0;
      &:first-child {
        background: var(--pinky);
        border-right: 1px solid #fff;
      }
      &:last-child {
        background: var(--greenish-teal);
        border-left: 1px solid #fff;
      }
    }
  }
}

.shop-modal-close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border: 1px solid #ccc;
  background: #fff;
}
