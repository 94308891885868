.shop-list {
    padding: 0 20px;

    h2 {
        text-align: center;
    }
}

.shop-list-item {
    background: #fff;
    border-radius: 7px;
    overflow: hidden;
    margin-bottom: 20px;

    &-info {
        padding: 20px 20px 0 20px;
    }

    &-actions {
        width: 100%;
        button {
            border: none;
            padding: 10px 0;
            color: #fff;
            font-size: 18px;
            width: 50%;

            &:first-child {
                padding: 0;
                background: var(--pinky);
                border-right: 1px solid #fff;

                a {
                    padding: 10px 0;
                    display: block;
                    color: #fff;
                }
            }
            &:last-child {
                background: var(--greenish-teal);
                border-left: 1px solid #fff;
            }
        }
    }
}