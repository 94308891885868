.product-page {
  background-color: #f9feff;
  color: #505050;
  width: 100%;
  overflow: hidden;

  &>* {
    padding: 0px 20px;
  }

  h2 {
     margin-bottom: 3px;
  }

  .product-availability {
    display: flex;
    margin-bottom: 8px;
    .availability-price {
      color: rgb(52,203,158);
      font-weight: bold;
    }
    .availability-days {
      margin-left: auto;
      color: rgb(244,151,182);
      font-style: italic;
    }
    p {
      margin: 0
    }
  }

  .product-image {
    img {
      width: 100%;
      background: #fff;
      border-radius: 7px;
      border: solid 1px #ededed;
    }
  }

  .product-tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    p {
      margin: 0 0 5px;
      width: 50%;

      span {
        margin-right: 5px;
      }
    }
  }

  .product-description {
    h3 {
      margin-top: 15px;
    }
  }

  .product-info {
    background-color: rgb(245,245,245);

    h3 {
      margin-top: 0;
      padding-top: 16px;
      margin-bottom: 10px;
    }

    .info-item {
      display: flex;
      border-bottom: 1px solid #cdcdcd;

      p {
        margin: 10px 0;
        width: 50%;
      }
    }
  }

  .allergens-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 25%;

      .allergen-name {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
      }
    }
  }

  .product-actions {
    display: flex;
    margin-bottom: 20px;

    button {
      width: calc(50% - 5px);
      height: 50px;
      border-radius: 20px;
      border: none;
      background-color: #cdcdcd;
      color: #fff;
      font-weight: bold;
      font-size: 18px;

      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 5px;
        background-color: #34cb9e;
      }
    }
  }
}
