.phone-input-container {
    background: transparent;
    width: auto !important;
}

.phone-input {
    background: transparent !important;
    color: #fff !important;
    border: none !important;
    border-bottom: 1px solid !important;
    border-radius: 0 !important;

    &.invalid-number {
        border-color: rgb(255, 44, 44) !important;
    }
}

.phone-input-button {
    background: rgba($color: #ccc, $alpha: 0.2);
}

.react-tel-input .form-control:focus.invalid-number {
    box-shadow: none;
}

.react-tel-input .invalid-number-message {
    bottom: -25px;
    left: 0;
    top: initial;
    font-weight: bold;
    background: transparent;
    font-size: 14px;
    color: rgb(255, 44, 44);
}