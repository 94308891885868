.order {
    padding-top: 20px;

    h2 {
        margin-top: 0;
        text-align: center;
    }

    .time {
        background-color: #f5f5f5;
        padding: 20px;

        p {
            margin-top: 0;
            font-weight: 300;
            font-size: 20px;
        }

        .order-time-input, select {
            padding: 5px 15px;
            border: none;
            width: 100%;
            border-bottom: 1px solid #000;
            background: transparent;
            font-size: 16px;
            font-family: inherit;
            margin-bottom: 30px;
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: '';
        }

        .shop-select-wrapper {
            position: relative;
            select {
                padding-right: 45px;
            }
            &::after {
                position: absolute;
                content: '';
                top: 8px;
                right: 20px;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid var(--dark);
            }
        }
        
    }
}