.product {
  opacity: 1;
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(175, 175, 175, 0.5);
  display: flex;
  transition: 0.2s linear;
  text-decoration: none;
  color: #505050;

  &.deleted {
    opacity: 0;
  }

  .product-info-wrapper {
    padding: 20px 15px;
    display: flex;
    min-width: 0;

    .product-image {
      width: 60px;
      flex-shrink: 0;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 15px;
      border: 1px solid #f5f5f5;

      img {
        //width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .product-fields {
      display: flex;
      flex-direction: column;

      .product-price {
        color: rgb(52,203,158);
        font-weight: bold;
      }
    }
  }

  .product-actions {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    width: 100px;

    button {
      background-color: #fff;
      width: 100%;
      height: 50%;
      border: none;
      border-left: 1px solid #f5f5f5;

      &:first-child {
        background-color: #f5f5f5;
      }
    }
  }
}
