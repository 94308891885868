.menu-item {
  a {
    color: inherit;
    text-decoration: none;
  }
}

.sub-menu {
  transition: all 0.3s ease-in-out;
  list-style: none;
  max-height: 0;
  overflow: hidden;
}

.sub-menu.active {
  max-height: 100px;
}
