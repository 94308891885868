.category {
  width: 60px;
  margin: 0 0 0 20px;
  cursor: pointer;
  z-index: -1;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    box-sizing: initial;
    padding-right: 20px
  }

  p {
    margin-top: 6px;
    margin-bottom: 0;
    font-size: 14px;
    opacity: .6;
    text-align: center;
  }
}

.category-icon-wrapper {
  height: 60px;
  border: solid 2px #f497b6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
