.shop-card-info {
  &-body {
    &-main {
      display: flex;
      &-left {
        flex-basis: 75%;
        p {
          margin: 0;
        }
        .main-info-name {
          font-weight: bold;
          margin-bottom: 5px;
        }
        .main-info-hours-before-closing {
          font-style: italic;
          color: var(--greenish-teal);
        }
      }
      &-right {
        margin-top: auto;
        margin-left: auto;
        color: rgb(80, 80, 80);
        opacity: 0.7;
      }
    }
    &-secondary {
      p {
        color: #505050;
        line-height: 1.3em;
      }
    }
  }
}
