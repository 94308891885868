#root {
  &.modal-open {
    height: 100vh;
    overflow-y: hidden;
  }
}

input { -webkit-border-radius: 0; }

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  background-color: #f9feff;
}

.basket-link {
  display: flex;
  justify-content: center;
}

.Toastify__toast {
  border-radius: 0; 
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: 1s;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: 1s;
}

* {
  outline: none;
}

:root {
  --greenish-teal: #34cb9e;
  --white: #ffffff;
  --orangey-red: #ee3b3b;
  --dark: #263238;
  --white-50: rgba(255, 255, 255, 0.5);
  --leather: #bd8d32;
  --very-light-pink-two: #cdcdcd;
  --tealish: #2aa8cb;
  --greenish-teal-two: #31c676;
  --grapefruit: #ff5151;
  --orangey-red-two: #ff3b30;
  --marigold: #ffcc00;
  --weird-green: #4cd964;
  --black: #000000;
  --dark-grey: #1c1c1e;
  --dark-grey-two: #2c2c2e;
  --coral: #ff453a;
  --dandelion: #ffd60a;
  --warm-blue: #5856d6;
  --robin-s-egg: #64d2ff;
  --reddish-pink: #ff2d55;
  --charcoal-grey: #3a3a3c;
  --deep-sky-blue: #0a84ff;
  --robin-s-egg-two: #5ac8fa;
  --bright-lilac: #bf5af2;
  --pale-grey: #f2f2f7;
  --tangerine: #ff9f0a;
  --dark-mint: #32d74b;
  --lightish-purple: #af52de;
  --tangerine-two: #ff9500;
  --cool-green: #34c759;
  --dark-periwinkle: #5e5ce6;
  --deep-sky-blue-two: #007aff;
  --reddish-pink-two: #ff375f;
  --blue-grey: #8e8e93;
  --pale-purple: #aeaeb2;
  --slate-grey: #636366;
  --light-blue-grey: #c7c7cc;
  --charcoal-grey-two: #48484a;
  --light-blue-grey-two: #d1d1d6;
  --pale-lilac: #e5e5ea;
  --brown-grey: #999999;
  --brownish-grey: #757575;
  --battleship-grey-24: rgba(118, 118, 128, 0.24);
  --charcoal-grey-18: rgba(60, 60, 67, 0.18);
  --battleship-grey-20: rgba(120, 120, 128, 0.2);
  --battleship-grey-12: rgba(118, 118, 128, 0.12);
  --battleship-grey-36: rgba(120, 120, 128, 0.36);
  --battleship-grey-32: rgba(120, 120, 128, 0.32);
  --pale-grey-30: rgba(235, 235, 245, 0.3);
  --light-blue-grey-three: #c6c6c8;
  --slate-grey-65: rgba(84, 84, 88, 0.65);
  --charcoal-grey-30: rgba(60, 60, 67, 0.3);
  --charcoal-grey-three: #38383a;
  --pale-grey-18: rgba(235, 235, 245, 0.18);
  --battleship-grey-8: rgba(116, 116, 128, 0.08);
  --pale-grey-60: rgba(235, 235, 245, 0.6);
  --charcoal-grey-29: rgba(60, 60, 67, 0.29);
  --charcoal-grey-60: rgba(60, 60, 67, 0.6);
  --battleship-grey-16: rgba(120, 120, 128, 0.16);
  --battleship-grey-18: rgba(116, 116, 128, 0.18);
  --pinky: #f497b6;
  --very-light-pink: #d5d5d5;
  --watermelon: #ee585a;
  --gunmetal: #3b4d56;
}

#content {
  padding: 0 20px 0 20px;
}

a {
  text-decoration: none;
}
