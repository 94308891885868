.header-wrapper {
  box-sizing: border-box;
  height: 70px;
  z-index: 100;
  padding: 22px 20px;
  position: fixed;
  width: 100%;
  background-color: #263238;
  display: flex;

  &>* {
    display: flex;
    flex: 1;
  }

  .header-logo {
    justify-content: center;
    align-content: center;

    svg {
      width: 100%;
      height: 50px;
      margin-top: -13px;
    }
  }

  .actions-wrapper {
    margin-left: auto;

    svg {
      margin-left: 15px;
    }
  }
}
