.auth-wrapper {
  background: url('assets/bg.svg');
  background-size: cover;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;

  .verification-input {
    display: flex;

    input {
      font-family: inherit;
      border: none;
      border-radius: none !important;
      background: transparent;
      box-shadow: none;
      padding-left: 0;
      width: 40px;
      font-size: 32px;
      color: #fff;
      margin: 0 5px;
      text-align: center;
      border-bottom: 2px solid;
    }
  }

  .auth-next-stage {
    width: 100%;
    position: absolute;
    bottom: 100px;
    display: flex;
    justify-content: center;
  }

  .auth-actions {
    width: 100%;
    display: flex;
    justify-content: center;

    &-action {
      position: relative;
      width: 90%;
      cursor: pointer;
      padding: 12px 0;
      text-align: center;
      border-radius: 25px;
      border: none;
      // opacity: .5;
      color: #fff;
      font-size: 14px;
      background: rgba(255, 255, 255, 0.5);
      @media (min-width: 321px) {
        width: auto;
        padding: 15px 60px;
        font-size: 16px;
      }

      .action-icon {
        width: 40px;
        height: 40px;
        background: var(--white);
        left: 5px;
        top: 4px;
        border-radius: 50%;
        position: absolute;
        font-size: 20px;
        line-height: 44px;

        @media (max-width: 320px) {
          display: none;
        }
      }
    }
  }

  .auth-back-to-website-link {
    position: absolute;
    bottom: 40px;

    a {
      color: #fff;
      opacity: 0.5;
    }
  }

  h1 {
    margin: 0;
  }
}
