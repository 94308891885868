#menu {
  position: fixed;
  top: 70px;
  background: #263238;
  left: -100%;
  transition: .2s ease-out;
  min-height: calc(100% - 70px);
  z-index: 10;

  &>ul {
    width: 300px;
    list-style: none;

    li {
      padding: 15px 0;
      color: #ffffff;

      &::before {
        content: "\2022";
        color: #f497b6;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
}

#menu.active {
  left: 0;
}
