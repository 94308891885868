.basket-products-wrapper {
    padding: 0 20px;

    h2 {
        text-align: center;
    }

    .total-title {
        background: rgb(194, 90, 90);
        position: relative;
        color: #fff;
        background: var(--gunmetal);
        font-size: 18px;
        font-weight: bold;
        left: -20px;
        right: -20px;
        width: calc(100% + 40px);
        padding: 10px 20px;
    }

    .total-info-table {
        width: 100%;
        font-size: 18px;
        margin-bottom: 15px;
        tr {
            td:nth-child(2) {
                text-align: right;
                font-weight: bold;
            }
        }
    }
}