.subcategories-main {
  cursor: pointer;
  top: 0;
  z-index: 1;
  outline: none;
  height: 34px;
  border-radius: 3px;
  border: solid 1px #e7e7e7;
  background: #fff;
  width: 100%;
  text-align: left;
  position: relative;
  margin-bottom: 10px;
  font-size: 14px;

  p {
    margin: 0 0 0 11px;
    line-height: 34px;
    font-weight: 600;
    opacity: .4;

    &.active {
      opacity: .9;
    }
  }

  svg {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 11px;
    top: 9px;
  }
}

.subCategories-modal {
  width: 300px;
  height: 80%;

  button {
    cursor: pointer;
    width: 100%;
    height: calc(10% - 10px);
    padding: 13px 0 9px;
    border-radius: 25px;
    border: none;
    font-size: 18px;
    color: #fff;
    background-color: #d5d5d5;
  }

  .subCategories-selector {
    padding: 0 11px 0 21px;
    border-radius: 3px;
    border: solid 1px #e7e7e7;
    background-color: #fff;
    height: 80%;
    overflow: scroll;
    margin: 10px 0;
  }

  .subCategories-actions {
    height: calc(10% - 10px);
    button {
      cursor: pointer;
      width: calc(50% - 12px);
      height: 100%;
      padding: 13px 0 9px;
      border-radius: 25px;
      border: none;
      font-size: 18px;
      color: #fff;
      background-color: #d5d5d5;

      &.active {
        background-color: #34cb9e;
      }

      &:first-child {
        margin-right: 12px;
      }
      &:last-child {
        margin-left: 12px;
      }
    }
  }

}


