.category-option {
  margin-top: 21px;
  display: flex;
  align-items: center;

  .option-checkbox {
    width: 30px;
    height: 30px;
    margin-right: 20px;
    border-radius: 5px;
    border: 1px solid #d5d5d5;
  }

  &.active {
    .option-checkbox {
      border-color: #34cb9e;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    span {
      font-weight: bold;
    }
  }
}


