.product-days {
  font-style: italic;
  color: #f497b6;
}

.basket-btn {
  width: calc(100% - 40px);
  border-radius: 25px;
  background: #34cb9e;
  border: none;
  padding: 15px 0 14px;
  position: fixed;
  color: #fff;
  font-size: 20px;
  bottom: 10px;
}
