.product-counter {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 170px;
  padding: 26px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: rgba(194,194,194, 0.5);

  button {
    &:first-child {
      border-color: #ee585a;
      color: #ee585a;
    }

    &:last-child {
      border-color: #34cb9e;
      color: #34cb9e;
    }
  }

  .amount-selection {
    margin-bottom: 20px;
    button {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 3px solid;
      //font-size: 48px;
      background: #fff;
      overflow: hidden;
      margin: 0 20px;
      //border-color: #ee585a;
    }
  }

  .counter-actions {
    width: 100%;
    display: flex;
    justify-content: center;
    button {
      padding: 15px 0 14px;
      border-radius: 25px;
      width: 45%;
      max-width: 155px;
      color: #fff;
      font-size: 20px;
      border-style: solid;

      &:first-child {
        margin-right: 20px;
        background: #ccc;
        border: 1px solid #ccc;
      }

      &:last-child {
        background: #34cb9e;
      }
    }


  }
}
