.search-input-wrapper {
  position: relative;
  margin-top: -12px;
  flex: 5;
  width: 100%;
  height: 50px;
}

.search-input {
  width: 100%;
  padding: 10px;
  height: 50px;
  border-radius: 3px;
  background: #3b4d56;
  border: none;
  color: #fff;
  font-size: 18px;
}

.search-input-actions {
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
